import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts'
import { Pagination, PaginationList } from '../components'
import { useTranslation } from '.'

const News = ({ data, pageContext: context, location }) => {
  const { t } = useTranslation(context?.locale)
  return (
    <Layout
      location={location}
      data={data.strapiArticlePage}
      meta={context?.meta}
      menu={context?.menu}
      locale={context?.locale}>
      <PaginationList
        all
        title={t('news')}
        paginationButton={t('Смотреть все новости')}
        entity="articles"
        locale={context.locale}
        items={data.allStrapiArticle.edges}>
        {context?.numberOfPages > 1 ? (
          <Pagination context={context}></Pagination>
        ) : (
          ''
        )}
      </PaginationList>
    </Layout>
  )
}

export const query = graphql`
  query NewsTemplate($skip: Int!, $limit: Int!, $locale: String!) {
    strapiArticlePage(locale: { eq: $locale }) {
      title
      background {
        url
      }
    }
    allStrapiArticle(
      filter: { locale: { eq: $locale } }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          title
          created_at
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 564, height: 440)
              }
            }
          }
          description
        }
      }
    }
  }
`

export default News
